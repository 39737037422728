import React, { useEffect } from "react";
import { HeaderWL, HelpButton } from "common";
import { Input, Form } from "common/form";
import { Container, Row, Col } from "react-bootstrap";
import styles from "content/flows/borrowerExperience/BorrowerExperience.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IApplicationVerification, IStepper
} from "common/interfaces";
import BorrowerHandler from "actions/BorrowerHandler";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import appActions from "reducers/AppReducer";
import { displayApplicationLockedFeedbackPopup, saveLoanApplicationToRedux } from "utils/helpers";
import LocalStorage from "classes/LocalStorage";
import borrowerActions from "reducers/BorrowerReducer";
import useMerchant from "hooks/useMerchant";
import { convertDateFormatSlashToDash } from "utils/formatters";
import { PersonalInfoValidation, Schema } from "utils/validation/additionalValidation";

const VerifyDOB: React.FC<IStepper> = ({ onBack }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");
  const merchant = useMerchant();
  const applicationLocked: boolean = LocalStorage.get<boolean>("application_locked");
  const visitedSteps: string[] = useAppSelector(state => state.app.borrowerVisitedSteps) ?? [];

  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    handleOffersEmailLink();
    if (applicationLocked && visitedSteps.length > 0 && visitedSteps[visitedSteps.length - 1].toLowerCase() === "offers") {
      displayApplicationLockedFeedbackPopup(() => { navigate(`/${merchant?.slug}/borrowerExperience/`) }, () => { navigate("/Offers") });
    }
  }, []);

  const handleOffersEmailLink = () => {
    // this means that this is the invitation link to the offers
    if (params.merchantSlug && params.application_id && location.pathname.toLowerCase().includes("offers")) {
      dispatch(borrowerActions.setSkipOffersMatchedSteps(true));
      navigate("/Offers");
      return;
    }
  }

  const handleSubmit = async (data: any) => {
    handleOffersEmailLink();

    if (applicationLocked) {
      dispatch(appActions.addVisitedStep("offers"));
      navigate("/Offers");
      return;
    }

    // get the application details
    const response = await BorrowerHandler.getApplicationData({
      date_of_birth: convertDateFormatSlashToDash(data.date_of_birth) ?? "",
    } as IApplicationVerification, applicationId, vuid);

    if (response.submitted_at) {
      dispatch(appActions.addVisitedStep("offers"));
      navigate("/Offers");
      return;
    }

    saveLoanApplicationToRedux(response);

    navigate(`/${merchant?.slug}/borrowerExperience/StartApplication`);
  }

  return (
    <>
      <HeaderWL />
      <main className={styles.borrowerExperienceContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col className={styles.helpCol1}>
              <HelpButton type="BORROWER" />
            </Col>
          </Row>
        </Container>
        <div className={styles.invitationFormContainer}>
          <Form
            id="borrowerExperience_verifyDOB"
            title="Verify Date of Birth"
            submitBtnText="Next"
            onFormSubmit={handleSubmit}
            validationSchema={Schema({
              date_of_birth: PersonalInfoValidation.date_of_birth,
            })}
            values={{
              date_of_birth: "",
            }}
            buttonPosition="side right"
            inputFields={[
              [
                <Input type="text" name="date_of_birth" id="borrowerExperience_dob" label="Date of birth" mask="date" />,
              ]
            ]}
            onBackClick={onBack}
          />
        </div>
      </main>
    </>
  );
};

export default VerifyDOB;
