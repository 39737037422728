import React, { useEffect, useState } from "react";
import styles from "./OffersTable.module.scss";
import { Button, Table } from "common";
import { useParams } from "react-router-dom";
import { IHighlight, IList } from "common/interfaces";
import { OFFER_TYPE, POPUPS } from "utils/constants";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import actions from "reducers/BorrowerReducer";
import { useAppDispatch } from "reducers/Hooks";
import { formatAPICurrency, formatAPIDate } from "utils/formatters";
import _ from "lodash";
import popUpActions from "reducers/PopUpReducer";
import Observer, { EVENTS } from "classes/Observer";
import SystemHandler from "actions/SystemHandler";
import { useAuth } from "auth/useAuth";

const OffersTable: React.FC = () => {
  const { applicantId } = useParams();
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState(null);
  const observerLoanAppUpdated = Observer.useObserver(EVENTS.LOAN_APP_UPDATED);
  const user = useAuth()?.user;
  const [rejectOfferOptions, setRejectOfferOptions] = useState([]);

  useEffect(() => {
    SystemHandler.getRejectOfferOptions().then(response => { setRejectOfferOptions(response) });
  }, []);

  useEffect(() => {
    if (applicantId) {
      getApplication();
    }
  }, [applicantId, observerLoanAppUpdated]);

  const getApplication = () => {
    // check cache first
    LoanApplicationHandler.get(applicantId, false)
      .then(response => {
        setDetails(response);
      });
  }

  const getOffers = async (next: string): Promise<IList> => {
    let offersLocal = await LoanApplicationHandler.getOffers(next, applicantId);
    offersLocal.results = offersLocal.results.map(result => {
      return {
        ...result,
        lender: result.lender_name,
        dateTime: formatAPIDate(result.date_time),
        offerType: result.pre_approved ? OFFER_TYPE.PRE_APPROVED : (result.pre_qualified ? OFFER_TYPE.PRE_QUALIFIED : "-"),
        termOfLoan: `${result.term} ${result.term_unit?.toLowerCase()}${result.term > 1 ? 's' : ''}`,
        monthlyPayment: formatAPICurrency(parseFloat(result.monthly_payment)),
        monthlyPaymentTerm: formatAPICurrency(parseFloat(result.monthly_payment)) + "/" + (result.term_unit as string).toLowerCase().substring(0, 2),
        maxLoan: formatAPICurrency(parseFloat(result.amount)),
        apr: `${parseFloat(result.apr).toFixed(2)}% ${result.apr_type}`,
        monthly_payment: parseFloat(result.monthly_payment),
        max_loan: parseFloat(result.amount),
        apr_num: parseFloat(result.apr),
        status: result.status,
        funded_amount: parseFloat(result.funded_amount || "0"),
        fundedAmount: formatAPICurrency(parseFloat(result.funded_amount || "0")),
        highlight: { highlighted: result?.status === "Funded", property: "lender_name" } as IHighlight
      }
    });

    offersLocal.results = _.filter(offersLocal.results, item => { return item.status !== "Canceled" });
    dispatch(actions.setApplicationOffers(offersLocal.results));

    return offersLocal;
  }

  return <div className={styles.contentContainer}>
    <div className={styles.titleWrapper}>
      <h2 className={styles.pageTitle} style={{ color: "var(--primaryVariationTextColor)" }}>Offers</h2>
      {/* we add "Declined by Consumer" in the future */}
      {(details?.status === "Offered" || details?.status === "Pending with Merchant") && <Button
        id="offers-table-recalculate-button"
        label={"Recalculate"}
        onClick={() => dispatch(popUpActions.openPopup({ name: POPUPS.RECALCULATION, message: details }))}
      />
      }
    </div>
    <div className={styles.tableContainer}>
      <Table
        id="merchantViewApplicant_offers"
        data={getOffers}
        tableBodyStyle={{ minWidth: 1000 }}
        headerWrapperStyle={{ minWidth: 1000 }}
        headers={[
          { label: "Lender", value: "lender", size: 8, sortBy: "lender_name" },
          { label: "Offer type", value: "offerType", size: 8, sortBy: "pre_approved" },
          { label: "Term of loan", value: "termOfLoan", size: 7, sortBy: "term" },
          { label: "Payment", value: "monthlyPaymentTerm", sortBy: "monthly_payment", size: 7 },
          { label: "Max loan", value: "maxLoan", sortBy: "amount", size: 7 },
          { label: "APR", value: "apr", sortBy: "apr", size: 7 },
          { label: "Status", value: "status", size: 6, sortBy: "status" },
          { label: "Funded", value: "fundedAmount", size: 6, sortBy: "funded_amount" },
        ]}
        maxHeight={500}
        // we should change "Pending with Merchant" with "Declined by Consumer" in the future
        headerBannerJSX={user?.user_type === "MERCHANT" && details?.status === "Pending with Merchant" && details?.applicant_reject_reason ?
          <div className={styles.headerBanner}>
            <div>
              <p>The borrower rejected these offers. Please recalculate.</p>
              <p>Reason: {rejectOfferOptions.find(option => { return option.id === details?.applicant_reject_reason })?.name}</p>
            </div>
            <Button
              id="offers-table-header-recalculate-button"
              label={"Recalculate"}
              onClick={() => dispatch(popUpActions.openPopup({ name: POPUPS.RECALCULATION, message: details }))}
            />
          </div> : undefined

        }
      />
    </div>
  </div>;
}

export default OffersTable;