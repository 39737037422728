import { configureStore } from '@reduxjs/toolkit'
import { appReducer } from './AppReducer';
import { whiteLabelReducer } from './WhiteLabelReducer';
import { merchantReducer } from './MerchantReducer';
import { borrowerReducer } from './BorrowerReducer';
import { animationReducer } from './AnimationReducer';
import { flowsReducer } from './FlowsReducer';
import { popUpReducer } from './PopUpReducer';
import { partnerReducer } from './PartnerReducer';
import { dtmApplicationReducer } from './DTMApplicationReducer';
import Log from 'classes/Log';

const store = configureStore({
  reducer: {
    app: appReducer,
    whitelabel: whiteLabelReducer,
    merchant: merchantReducer,
    borrower: borrowerReducer,
    animation: animationReducer,
    flows: flowsReducer,
    popUp: popUpReducer,
    partner: partnerReducer,
    dtmApplication: dtmApplicationReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'popUp/displayFeedbackPopup',
          'popUp/closePopup',
          'popUp/openPopup',
          'app/updateMerchantFilter',
          'app/updateApplicationFilter',
          'app/updatePartnerFilter',
          'borrower/setOfferInfo',
        ],
        ignoredPaths: [
          'payload.created_from',
          'payload.date_from',
          'popUp.feedbackPopup.buttons',
          'popUp.messagePassing.noAction',
          'popUp.messagePassing.yesAction',
          'popUp.messagePassing.onSend',
          'app.tableFilters',
          'borrower.offerInfo.onClick',
          'popUp.messagePassing.yesButton.action',
          'popUp.messagePassing.noButton.action',
          'popUp.messagePassing.body.$$typeof',
          'popUp.messagePassing.body'
        ],
      },
    })
});

store.subscribe(() => {
  Log.debug(store.getState());
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;