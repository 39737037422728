import styles from "./RejectOfferPopup.module.scss";
import { AppIcon, Padding } from "common";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IFeedback, IPopup } from "common/interfaces";
import Popup from "./Popup";
import { displayFeedback } from "utils/helpers";
import Observer, { EVENTS } from "classes/Observer";
import BorrowerHandler from "actions/BorrowerHandler";
import LocalStorage from "classes/LocalStorage";
import { ArrowLeft } from "iconsax-react";
import popUpActions from "reducers/PopUpReducer";
import { Form, SelectDropdown } from "common/form";
import { Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";
import SystemHandler from "actions/SystemHandler";
import { formatAmount } from "utils/formatters";
import { useNavigate } from "react-router-dom";

const RejectOfferPopup: React.FC<IPopup> = () => {
  const message = useAppSelector(state => state.popUp.messagePassing);
  const [rejectOfferOptions, setRejectOfferOptions] = useState([]);

  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    SystemHandler.getRejectOfferOptions().then(response => { setRejectOfferOptions(response) });
  }, []);

  const handleSubmit = async (data: any) => {
    const payload = {
      applicant_reject_reason: data?.reason
    };

    const response = await BorrowerHandler.rejectOffer(applicationId, vuid, payload);
    Observer.trigger(EVENTS.LOAN_APP_UPDATED);
    navigate("offerRejected");
    dispatch(popUpActions.closePopup());
  }

  return (
    <Popup isInner={false}>
      <Padding>
        <div className={styles.backIcon}>
          <AppIcon
            clickTrigger={{
              id: `rejectOffer_arrowLeft`,
              onClick: () => {
                dispatch(popUpActions.closePopup());
              }
            }}
            size={26}
            color="var(--darkTextColor)"
            className={styles.backIcon}
            icon={ArrowLeft}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "2.5rem" }}>
          {message?.borrower?.offers[0]?.logo_url && <div className={styles.brandLogo}>
            <img src={message?.borrower?.offers[0]?.logo_url} alt="lender logo" />
          </div>}
          <p className={`${styles.p1} big`}>{`You are about to reject a prequalified loan offer for $${formatAmount(message?.borrower?.loanInformation?.loan_amount)} with ${message?.borrower?.offers[0]?.lender_name}?`}</p>
          <h4 className={styles.subtitle}>By clicking confirm rejection, the merchant will be notified. They can then adjust the offer and send you another. To simplify the process, select a reason for rejection below, letting them know what should be adjusted.</h4>
          <Form
            id="rejectOfferPopup"
            title=""
            submitBtnText="Confirm rejection"
            onFormSubmit={handleSubmit}
            validationSchema={Schema({
              reason: SimpleRequireValidation,
            })}
            values={{
              reason: "",
            }}
            buttonPosition={"bottom center"}
            inputFields={[[
              <SelectDropdown id="rejectOffer_reason" label="Reason*" name="reason" placeholder="Select" selectOptions={rejectOfferOptions} />
            ]]}
            isInsidePopup
            styleOverride={{ formContainer: { maxWidth: "30rem" } }}
          />
          <div />
        </div>
      </Padding>
    </Popup >
  );
};

export default RejectOfferPopup;
