import {
  IAddressInfo, IAgreement, IApplicationVerification, IDTCFinancialInfo,
  IDTMFinancialInfo, IDownPayment, ILoanInformation, IMainContactBorrower, IPersonalInformation, ISSN, IRejectOffer,
} from "common/interfaces";
import { ENABLE_FINANCING_API_INSTANCE, ENABLE_FINANCING_API_INSTANCE_NO_TOKEN } from "./ActionConstants";
import ErrorHandler from "./ErrorHandler";
import { displayDuplicatedApplicationFeedbackPopup, generateCaptcha, getAPIErrorForTracking } from "utils/helpers";
import EnableCache from "classes/EnableCache";
import Observer, { EVENTS } from "classes/Observer";
import moment from "moment";
import Analytics, { ITracking } from "classes/Analytics";
import { stripOutNonDigits } from "utils/formatters";
import store from "reducers/Store";

export default class BorrowerHandler {
  /**
   * @description Verify and retrieve application flow data.
   * @param {IApplicationVerification} application_verification Application verification data.
   * @param {number} application_id Application id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static getApplicationData = async (application_verification: IApplicationVerification, application_id: number, vuid: string): Promise<any> => {
    try {
      let response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`loan-applications/applications/${application_id}/?vuid=${vuid}&format=json&date_of_birth=${application_verification.date_of_birth}`);
      if (response.data?.phone_number && response.data.phone_number.length > 10 && response.data.phone_number.startsWith("+1")) {
        response.data.phone_number = stripOutNonDigits(response.data.phone_number).substring(1);
      }
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
 * @description Verify and retrieve application flow data without DOB
 * @param {IApplicationVerification} application_verification Application verification data.
 * @param {number} application_id Application id.
 * @param {string} vuid vuid sent on the invitation email.
 * @returns {Promise<any>} API response.
 */
  static getApplicationDataWithoutDOB = async (application_id: number, vuid: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`loan-applications/applications/${application_id}/?vuid=${vuid}&format=json`);
      return Promise.resolve(response.data);

    } catch (error) {
      // in this case, you don't want to show the user any error with ErrorHandler
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Save new empty loan application.
   * @param {string} location_id Location id.
   * @returns {Promise<any>} API response.
   */
  static createLoanApplication = async (location_id: string): Promise<any> => {
    try {
      await generateCaptcha();
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.post(`loan-applications/applications/`, { location_id });
      EnableCache.remove("recaptcha");
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Update loan information.
   * @param {ILoanInformation} loan_information Loan information.  
   * @param {number} application_id Application id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static updateLoanInformation = async (loan_information: ILoanInformation, application_id: number, vuid: string): Promise<any> => {
    const payload = {
      loan_amount: loan_information.loan_amount,
      loan_purpose: loan_information.loan_purpose,
    };

    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.patch(`loan-applications/applications/${application_id}/?vuid=${vuid}`, payload);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      Analytics.track({ experience: "borrower", screen: "application_loan_info", object: "form_submission", action: "successful" } as ITracking, null);
      return Promise.resolve(response.data);

    } catch (error) {
      Analytics.track({ experience: "borrower", screen: "application_loan_info", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Save personal information.
   * @param {IPersonalInformation} personal_information Personal information.  
   * @param {number} application_id Application id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static savePersonalInformation = async (personal_information: IPersonalInformation, application_id: number, vuid: string): Promise<any> => {
    try {
      // If an external id was provided, that is a good spot to save the information
      let payload: any = {
        ...personal_information,
        external_id: store.getState().borrower.additionalInformation?.external_id || null
      };

      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.patch(`loan-applications/applications/${application_id}/?vuid=${vuid}`, payload);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      Analytics.track({ experience: "borrower", screen: "application_personal_info", object: "form_submission", action: "successful" } as ITracking, null);
      return Promise.resolve(response.data);

    } catch (error) {
      Analytics.track({ experience: "borrower", screen: "application_personal_info", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Save Main Contact information.
   * @param {IMainContactBorrower} main_contact_information Main contact information.  
   * @param {number} application_id Application id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static saveMainContactInformation = async (main_contact_information: IMainContactBorrower, application_id: number, vuid: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.patch(`loan-applications/applications/${application_id}/?vuid=${vuid}`, main_contact_information);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      Analytics.track({ experience: "borrower", screen: "application_contact_info", object: "form_submission", action: "successful" } as ITracking, null);
      return Promise.resolve(response.data);

    } catch (error) {
      Analytics.track({ experience: "borrower", screen: "application_contact_info", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
      if (error?.response?.status === 400 && error?.response?.data?.email?.length > 0 && error?.response?.data?.email[0]?.indexOf('You may submit another') > -1) {
        displayDuplicatedApplicationFeedbackPopup(error.response.data.email[0], "email", error.response.data.email[0].indexOf('check your existing application') > -1);
        return Promise.reject();
      }
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Save down payment information.
   * @param {IDownPayment} down_payment_information Down payment information.  
   * @param {number} application_id Application id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static saveDownPaymentInformation = async (down_payment_information: IDownPayment, application_id: number, vuid: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.patch(`loan-applications/applications/${application_id}/?vuid=${vuid}`, down_payment_information);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      Analytics.track({ experience: "borrower", screen: "application_down_payment_info", object: "form_submission", action: "successful" } as ITracking, null);
      return Promise.resolve(response.data);

    } catch (error) {
      Analytics.track({ experience: "borrower", screen: "application_down_payment_info", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Save Address information.
   * @param {IAddressInfo} address_information Address information.  
   * @param {number} application_id Application id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static saveAddressInformation = async (address_information: IAddressInfo, application_id: number, vuid: string): Promise<any> => {

    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.patch(`loan-applications/applications/${application_id}/?vuid=${vuid}`, address_information);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      Analytics.track({ experience: "borrower", screen: "application_address_info", object: "form_submission", action: "successful" } as ITracking, null);
      return Promise.resolve(response.data);

    } catch (error) {
      Analytics.track({ experience: "borrower", screen: "application_address_info", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Save Financial information.
   * @param {IDTCFinancialInfo | IDTMFinancialInfo} financial_information Financial information.  
   * @param {number} application_id Application id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static saveFinancialInformation = async (financial_information: IDTCFinancialInfo | IDTMFinancialInfo, application_id: number, vuid: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.patch(`loan-applications/applications/${application_id}/?vuid=${vuid}`, financial_information);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      Analytics.track({ experience: "borrower", screen: "application_financial_info", object: "form_submission", action: "successful" } as ITracking, null);
      return Promise.resolve(response.data);

    } catch (error) {
      Analytics.track({ experience: "borrower", screen: "application_financial_info", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Save SSN number.
   * @param {ISSN} ssn SSN.
   * @param {number} application_id Application id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static saveSSN = async (ssn: ISSN, application_id: number, vuid: string): Promise<any> => {

    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.patch(`loan-applications/applications/${application_id}/?vuid=${vuid}`, ssn);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      Analytics.track({ experience: "borrower", screen: "application_ssn", object: "form_submission", action: "successful" } as ITracking, null);
      return Promise.resolve(response.data);

    } catch (error) {
      Analytics.track({ experience: "borrower", screen: "application_ssn", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
      if (error?.response?.status === 400 && error?.response?.data?.non_field_errors?.length > 0 && error?.response?.data?.non_field_errors[0]?.indexOf('You may submit another') > -1) {
        displayDuplicatedApplicationFeedbackPopup(error.response.data.non_field_errors[0], "Social security number", error.response.data.non_field_errors[0].indexOf('check your existing application') > -1);
        return Promise.reject();
      }
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Save Agreement.
   * @param {boolean} agreement Agreement.
   * @param {number} application_id Application id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static saveAgreement = async (agreement: IAgreement, application_id: number, vuid: string): Promise<any> => {

    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.patch(`loan-applications/applications/${application_id}/?vuid=${vuid}`, agreement);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get loan offers.
   * @param {number} application_id Application id.
   * @param {string} vuid vuid sent on the invitation email. If null, that means that merchant is getting a DTM loan application
   * @returns {Promise<any>} API response.
   */
  static getOffers = async (application_id: number, vuid?: string): Promise<any> => {
    try {
      let response = null;
      if (!vuid) {
        response = await ENABLE_FINANCING_API_INSTANCE.get(`loan-applications/applications/${application_id}/offers/?limit=1000`);
      } else {
        response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`loan-applications/applications/${application_id}/offers/?vuid=${vuid}&limit=1000`);
      }
      const responseDataModified = {
        ...response.data,
        x_progress: Number(response.headers['x-progress']),
      };

      return Promise.resolve(responseDataModified);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get invitation.
   * @param {number} application_id Application id.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns {Promise<any>} API response.
   */
  static getInvitation = async (application_id: number, vuid: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`loan-applications/applications/${application_id}/?vuid=${vuid}`);
      return Promise.resolve({
        ...response.data,
        invitationStatus: 'VALID',
        applicationHasDOB: false,
      });
    } catch (error) {
      if (error?.response?.status === 400 && error?.response?.data?.date_of_birth === "Date of birth does not match") {
        return Promise.resolve({
          invitationStatus: 'VALID',
          applicationHasDOB: true,
        });
      }
      else if (error?.response?.status === 401 && error?.response?.data?.details === "Invalid visitor pass.") {
        return Promise.resolve({
          invitationStatus: 'INVALID',
          applicationHasDOB: false,
        });
      } else {
        ErrorHandler(error);
      }
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Searches for a loan application based on DOB and last 4 digits of SSN. Returns the application ID and a JWT token if found.
   * @param {string} date_of_birth Date of birth.
   * @param {string} ssn_last_4 Last 4 SSN.
   * @returns {Promise<any>} API response.
   */
  static searchApplicationData = async (date_of_birth: string, ssn_last_4: string): Promise<any> => {
    try {
      const payload = {
        date_of_birth: moment(date_of_birth).format('MM-DD-YYYY'),
        ssn_last_4
      }
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.post(`loan-applications/search/`, payload);
      Analytics.track({ experience: "borrower", screen: "revisit_offers", object: "verification_attempt", action: "successful" } as ITracking, null);
      return Promise.resolve(response.data);

    } catch (error) {
      Analytics.track({ experience: "borrower", screen: "revisit_offers", object: "verification_attempt", action: "unsuccessful" } as ITracking, { error_name: error?.response?.data?.detail });
      if (error?.response?.status === 429) {
        return Promise.resolve({
          error: "TOO_MANY_ATTEMPTS"
        });
      } else if (error?.response?.status === 404 && error?.response?.data?.detail === "No application found.") {
        return Promise.resolve({
          error: "APP_NOT_FOUND"
        });
      }
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
  * @description Patch loan information.
  * @param {any} data Loan information.  
  * @param {number} application_id Application id.
  * @param {string} vuid vuid sent on the invitation email. If null, that means that merchant is modifying a DTM loan application
  * @returns {Promise<any>} API response.
  */
  static patch = async (data: any, application_id: number, vuid?: string): Promise<any> => {
    try {
      let response = null;
      if (!vuid) {
        response = await ENABLE_FINANCING_API_INSTANCE.patch(`loan-applications/applications/${application_id}/`, data);
      } else {
        response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.patch(`loan-applications/applications/${application_id}/?vuid=${vuid}`, data);
      }

      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      Analytics.track({ experience: "borrower", screen: "application_confirmation", object: "form_submission", action: "successful" } as ITracking, null);
      return Promise.resolve(response.data);

    } catch (error) {
      Analytics.track({ experience: "borrower", screen: "application_confirmation", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
* @description Reject offer
* @param {number} application_id Application Id.
* @param {string} vuid vuid sent on the invitation email.
* @returns {Promise<any>} API response.
*/
  static rejectOffer = async (application_id: number, vuid: string, payload: IRejectOffer): Promise<any> => {
    try {
      const url = `loan-applications/applications/${application_id}/applicant-declined/?vuid=${vuid}`;
      const response = await ENABLE_FINANCING_API_INSTANCE.post(url, payload);
      Observer.trigger(EVENTS.LOAN_APP_UPDATED);
      return Promise.resolve(response.data);
    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
      // return Promise.resolve(null);
    }
  }
}