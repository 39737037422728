import styles from "./LandingPageSettingsPopup.module.scss";
import { Button, CloseIcon } from "common";
import { FormikError, SlugTextField } from "common/form";
import { InfoCircle } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { Schema, URLFriendlyText } from "utils/validation/additionalValidation";
import { decypherApiErrorResponse, displayMiniFeedback, isForMerchantGlobal } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import { MerchantHandler } from "actions/MerchantHandler";
import { useParams } from "react-router-dom";
import Analytics, { ITracking } from "classes/Analytics";
import { useAuth } from "auth/useAuth";
import useMerchant from "hooks/useMerchant";

const LandingPageSettingsPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const { merchantSlug } = useParams();
  const [toolTip, setToolTip] = useState(false);
  const [uploadedFile, setUploadedFile] = React.useState<any>("");
  const merchant = useMerchant();
  const whitelabel = useAppSelector(state => state.whitelabel);
  const user = useAuth()?.user;
  const [ready, setReady] = useState(false);

  const formik = useFormik({
    initialValues: {
      slug: merchantSlug,
      primary_logo: "",
    },
    validationSchema: Schema({
      slug: URLFriendlyText
    }),
    async onSubmit(values) {
      if (isForMerchant()) {
        try {
          const currentLogo = merchant?.primary_logo;
          const currentSlug = merchant?.slug;
          const updatedMerchant = await MerchantHandler().patchWithLogo(uploadedFile, merchant?.slug, values.slug);
          if (uploadedFile?.name !== currentLogo) {
            Analytics.track({ experience: "portal", screen: (user?.user_type === "WHITELABEL" && merchantSlug) ? "merchants_landing_page" : "settings_landing_page", object: "logo", action: "updated" } as ITracking, { merchant_id: updatedMerchant?.id });
          }
          if (updatedMerchant?.slug !== currentSlug) {
            Analytics.track({ experience: "portal", screen: (user?.user_type === "WHITELABEL" && merchantSlug) ? "merchants_landing_page" : "settings_landing_page", object: "url", action: "updated" } as ITracking, { merchant_id: updatedMerchant?.id });
          }
          displayMiniFeedback("Landing page was updated");
          dispatch(popUpActions.closePopup());
        } catch (error) {
          displayMiniFeedback(decypherApiErrorResponse(error), true);
        }
      }
    },
  });

  useEffect(() => {
    if (isForMerchant()) {
      MerchantHandler().get(merchantSlug || merchant?.slug)
    }
  }, [merchantSlug]);

  useEffect(() => {
    return () => {
      setUploadedFile("");
      formik.setFieldValue("primary_logo", "");
    };
  }, []);

  useEffect(() => {
    if (merchant && whitelabel) {
      if (isForMerchant()) {
        formik.setFieldValue("slug", merchant?.slug);
        formik.setFieldValue("primary_logo", merchant?.primary_logo || null);
      }
      setReady(true);
    }
  }, [merchant, whitelabel]);

  const isForMerchant = () => {
    return isForMerchantGlobal(user, merchantSlug);
  }

  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="landingPageSettingsPopup" color="dark" />
        {ready && <form onSubmit={formik.handleSubmit}>
          <h2>Landing page settings</h2>
          <div className={styles.inviteFormContainer}>
            <div className={styles.inviteFormRow}>
              <div>
                <label className="label" style={{ marginBottom: 20 }}>Landing page URL</label>
                <SlugTextField
                  id="landingPageSettingsPopup_slug"
                  formik={formik} />
              </div>
              <div
                className={`${styles.formField} ${styles.uploadLogoContainer}`}>
                <div className={styles.labelContainer}>
                  <p>Logo</p>
                  <InfoCircle size={20} color="var(--darkTextColor)"
                    onMouseEnter={() => setToolTip(true)}
                    onMouseLeave={() => setToolTip(false)}
                  />
                  {toolTip && (
                    <div className={`${styles.pop} ${styles.pop2}`}>
                      <p className="info">
                        Image criteria for logos:
                        <ul>
                          <li>At least 100px wide</li>
                          <li>Transparent or white background</li>
                          <li>PNG or SVG</li>
                        </ul>
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.uploadContainer}>
                  <input
                    type="file"
                    id="landingPageSettingsPopup_actual-btn"
                    name="primary_logo"
                    hidden
                    onChange={(e: any) => {
                      formik.setFieldValue("primary_logo", e.target.files[0].name);
                      setUploadedFile(e.target.files[0]);
                    }}
                  />
                  <label htmlFor="landingPageSettingsPopup_actual-btn">Upload</label>
                  <span id={`fileChosen ${styles.choosenFile}`} style={{
                    width: 330,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "unset"
                  }} title={formik.values.primary_logo}>
                    {formik.values.primary_logo}
                  </span>
                </div>
                <FormikError formik={formik} propertyName="primary_logo" />
              </div>
            </div>
            <div className={styles.btnContainer}>
              <Button id="landingPageSettingsPopup_submit" type="submit" label="Save" />
            </div>
          </div>
        </form>}
      </>
    </Popup >
  );
};

export default LandingPageSettingsPopup;
