import "utils/constants";
import ReactDOM from 'react-dom/client';
import App from 'App';
import 'styles/global.scss';
import Store from 'reducers/Store';
import { Provider } from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import { loadGAScript, loadNewRelicScript, loadLogRocket } from "utils/scripts";
import { FeatureFlagProvider } from 'hooks/useFlag';

loadGAScript();
loadNewRelicScript();
loadLogRocket();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
    <FeatureFlagProvider>
      <App />
    </FeatureFlagProvider>
  </Provider>
);