import React from "react";
import styles from "./ApplicationStage.module.scss";
import { Icon } from "iconsax-react";
import { AppIcon } from "common";

interface IApplicationStage {
    icon: Icon,
    text: string,
    completed: boolean,
    amount?: string | number,
    date?: string,
    showLine: boolean,
}


const ApplicationStage: React.FC<IApplicationStage> = ({ icon, text, completed, amount, date, showLine }) => {
    return <>
        <div className={styles.stage}>
            {showLine && <div className={styles.line} />}
            <div className={styles.icon_wrapper}>
                <AppIcon color="var(--primaryTextColor)" size={22} icon={icon} circleBackground={true} circleColor={!completed ? "var(--primaryColor40Solid)" : ""} reverseColors={completed ? true : false} />
            </div>
            <div className={styles.stageDetailsWrapper}>
                <div className={styles.statusAndAmountWrapper}>
                    <span className={styles.status}>{text}{amount ? " •" : ""}</span>
                    <span className={styles.amount}> {amount}</span>
                </div>
                <p className={styles.date}>{date}</p>
            </div>
        </div>
    </>;
}

export default ApplicationStage;