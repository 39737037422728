import { IDTMApplication, IDTMOwner, ILocation } from "common/interfaces";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { isNullOrUndefinedOrEmpty, isNullOrUndefined, tryParseNumber, isUndefinedNullOrZero, isOnlyAsterisks } from "./helpers";

export const formatPhoneNumber = (value: string) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const formatSSNNumber = (value: String) => {
  if (!value) return value;

  const ssnNumber = value.replace(/[^\d]/g, "");

  const ssnLength = ssnNumber.length;

  if (ssnLength < 3) return ssnNumber;

  if (ssnLength < 6) {
    return `${ssnNumber.slice(0, 2)}-${ssnNumber.slice(2)}`;
  }

  return `${ssnNumber.slice(0, 3)}-${ssnNumber.slice(3, 5)}-${ssnNumber.slice(5, 9)}`;
};

export const formatEINNumber = (value: string) => {
  if (!value) return value;

  const einNumber = value.replace(/[^\d]/g, "");

  const einLength = einNumber.length;

  if (einLength < 3) return einNumber;  // If less than two digits, return them as is.

  // Format as ##-####### after more than two digits.
  return `${einNumber.slice(0, 2)}-${einNumber.slice(2)}`;
};

export const formatInputDate = (value: String) => {
  if (!value) return value;

  const dateInput = value.replace(/[^\d]/g, "");

  const dateInputLength = dateInput.length;

  if (dateInputLength <= 2) return dateInput;

  if (dateInputLength <= 4) {
    return `${dateInput.slice(0, 2)}/${dateInput.slice(2)}`;
  }

  return `${dateInput.slice(0, 2)}/${dateInput.slice(2, 4)}/${dateInput.slice(
    4,
    8
  )}`;
};

export const formatPercent = (value: String) => {
  if (!value) return value;

  const percent = value.replace(/[^\d]/g, "");

  return percent;
};

export const formatAmount = (value: string | number, displayPercentage: boolean = false, minimumFractionDigits = 0, maximumFractionDigits = 0) => {
  if (!value)
    return displayPercentage ? "0%" : "0";

  value = value.toString().replace(/[^\d.]/g, "");
  value = Number(value).toFixed(12)// otherwise 0.28 * 100 results in 28.000000000000004 instead of 28

  const options = {
    style: "decimal",
    minimumFractionDigits,
    maximumFractionDigits,
  };

  let returnValue = new Intl.NumberFormat("en-US", options).format(Number(value));

  if (displayPercentage) {
    returnValue += "%";
  }

  return returnValue;
};

/**
 * @description Rounds the number and calls formatAmount and adds $ in the front
 */
export const formatAmount2 = (value: string) => {
  if (value == null || !Number.isFinite(Number(value))) {
    return "-";
  }
  return "$" + formatAmount(value);
}

/**
 * @description Similar to formatAmount, but rounds the number to 4 digits instead of 0
 */
export const formatAmount3 = (value: string | number) => {
  if (!value) return "0";
  value = value.toString().replace(/[^\d.]/g, "");
  value = Number(value).toFixed(12)// otherwise 0.28 * 100 = 28.000000000000004

  const options = {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  };

  let returnValue = new Intl.NumberFormat("en-US", options).format(Number(value));
  return returnValue;
};

/** @description Strip out non digits.
 * @param {string} text Text.
 * @returns String with non digits.
 */
export function stripOutNonDigits(text: string) {
  if (!text) {
    return "";
  }
  return text?.toString().replace(/\D/g, '');
}

/**
 * Converts a date string from the format "MM/DD/YYYY" to "MM-DD-YYYY".
 *
 * @param {string} dateWithSlashes - The date string with slashes.
 * @returns {string} The date string with dashes, or an empty string if the input is falsy.
 */
export const convertDateFormatSlashToDash = (dateWithSlashes: string): string => {
  if (isNullOrUndefinedOrEmpty(dateWithSlashes)) {
    return "";
  }
  return dateWithSlashes ? dateWithSlashes.replace(/\//g, "-") : "";
}

/**
 * Converts a date string from the format "MM-DD-YYYY" to "MM/DD/YYYY".
 *
 * @param {string} dateWithDashes - The date string with dashes.
 * @returns {string} The date string with slashes, or an empty string if the input is falsy.
 */
export const convertDateFormatDashToSlash = (dateWithDashes: string): string => {
  if (isNullOrUndefinedOrEmpty(dateWithDashes)) {
    return "";
  }
  return dateWithDashes ? dateWithDashes.replace(/-/g, "/") : "";
}

/**
 * @description Format API date to user friendly format.
 * @param {string} date Date ISO format.
 * @param {boolean} displayTime Display time?.
 * @param {boolean} userTimeZone Use user time zone?.
 * @return MM/DD/YY format or MM/DD/YY hh:mi:ss.
 * */
export const formatAPIDate = (date: string, displayTime?: boolean, userTimeZone?: boolean): string => {
  if (!date) {
    return "-";
  }
  let format = !displayTime ? 'MM/DD/YY' : 'MM/DD/YY h:mm a';
  if (userTimeZone) {
    return momentTimeZone.tz(date, moment.tz.guess()).format(format);
  }
  return moment.utc(date).format(format);
}

/**
 * @description Format API date to user friendly format.
 * @param {string} date Date ISO format.
 * @param {boolean} displayTime Display time?.
 * @param {boolean} userTimeZone Use user time zone?.
 * @return MM/DD/YYYY format or MM/DD/YYYY hh:mi:ss.
 * */
export const formatAPIDateFullYear = (date: string, displayTime?: boolean, userTimeZone?: boolean): string => {
  if (!date) {
    return "-";
  }
  let format = !displayTime ? 'MM/DD/YYYY' : 'MM/DD/YYYY h:mm a';
  if (userTimeZone) {
    return momentTimeZone.tz(date, moment.tz.guess()).format(format);
  }
  return moment.utc(date).format(format);
}

/**
 * @description Format API phone to user friendly format.
 * @param {string} phone Phone in format +11235550010.
 * @return (123) 555-0010.
 * */
export const formatAPIPhone = (phone: string): string => {
  if (!phone) {
    return "-";
  }
  if (phone.length < 12) {
    return phone;
  }
  return `(${phone.substring(2, 5)}) ${phone.substring(5, 8)}-${phone.substring(8, 12)}`;
}

/**
 * @description Format phone to user friendly format.
 * @param {string} phone Phone in format 1235550010.
 * @return (123) 555-0010.
 * */
export const formatPhone = (phone: string): string => {
  if (!phone) {
    return "";
  }
  if (phone.length > 10) {
    return formatAPIPhone(phone);
  }
  return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
}

/**
 * @description Format API text.
 * @param {string} text Text to format.
 * @return Text or "-"" if the string is empty.
 * */
export const formatAPIString = (text: string): string => {
  if (!text || text === "null null" || text === "null") {
    return "-";
  }
  return text;
}

/**
 * @description Format API currency to user friendly format.
 * @param {number} amount Amount.
 * @param {boolean} displayDecimals If decimals should be displayed.
 * @return $ 000.00 format.
 * */
export const formatAPICurrency = (amount: number, displayDecimals: boolean = true): string => {
  if (amount == null || isNaN(amount)) {
    return "-";
  }
  if (amount === 0) {
    return displayDecimals ? "$0.00" : "$0";
  }

  const sign = amount < 0 ? '-' : '';
  const absNum = Math.abs(amount).toFixed(2);
  const [intPart, decPart] = absNum.split('.');

  let res = '';
  for (let i = intPart.length - 1, j = 0; i >= 0; i--, j++) {
    if (j % 3 === 0 && j !== 0) {
      res = `,${res}`;
    }
    res = intPart[i] + res;
  }

  return displayDecimals ? `${sign}$${res}.${decPart}` : `${sign}$${res}`;
}

export const formatLocationDisplay = (location: ILocation) => {
  if (!location) {
    return '';
  }
  return `${location.location_name === 'main_location' || location.name === 'main_location' ? 'Main location' : location.location_name || location.name}: ${location.address1}, ${location.city}, ${location.state} ${location.postal_code}`;
}

export const cleanNumber = (input: string): string => {
  if (isUndefinedNullOrZero(input))
    return "0";

  return input.replace(/[^\d.]+/g, '');
}

export const formatFullname = (first_name: string, last_name: string): string => {
  const capitalize = (name: string) =>
    name.split(' ')
      .map(word => {
        if (isNullOrUndefinedOrEmpty(word)) {
          return "";
        } else {
          return word[0].toUpperCase() + word.slice(1);
        }
      }).join(' ');

  return [first_name || "", last_name || ""].map(capitalize).filter(name => !isNullOrUndefinedOrEmpty(name)).join(' ') || '-';
}

/* ####################################################################################*/

/**
 * Parses information in a API/UI friendly format.
 **/
const DTM_PARSER = {
  API: {
    date: (date: string): string => date ? moment(date).format('MM-DD-YYYY') : null,
    phone: (phone: string): string => {
      if (!phone) return null;
      const newPhone = stripOutNonDigits(phone);
      if (newPhone.length > 10) return newPhone.substring(1);
      return newPhone;
    },
    amount: (amount: string): number => {
      if (!amount) return 0;
      return tryParseNumber(cleanNumber(amount), true);
    },
    handleStringFiles: (file: any): any => {
      return isNullOrUndefinedOrEmpty(file) || typeof (file) === "string" ? null : file;
    },
    handleYesAndNo: (option: string): boolean => {
      return option === "YES" ? true : false;
    }
  },
  UI: {
    /* if you try to covert date from string with "-" moment library in Safari, it throws Invalid date error - but it works with "/"
    everywhere else we are converting Date object instead of string, so there is no problem */
    date: (date: string): string => date ? moment(convertDateFormatDashToSlash(date)).format('MM/DD/YYYY') : null,
    phone: (phone: string): string => phone ? formatAPIPhone(phone) : null,
    int: (amount: string): string => {
      if (!amount) return "";
      const num = tryParseNumber(amount, false);
      return num.toString();
    },
    decimals: (amount: string): string => {
      if (!amount) return "";
      const num = tryParseNumber(amount, true);
      return num === 0 ? "" : num.toFixed(2);
    },
    hiddenInfo: (info: string): string => {
      return info;
    },
    handleYesAndNo: (option: any): string => {
      return option ? "YES" : "NO";
    }
  }
}

const LOAN_APP_SOURCE_MAPPING = {
  HANDOFF: "Handoff",
  EMAIL: "Invitation via Email",
  PHONE: "Invitation via SMS",
  BULK_IMPORT: "Bulk Import",
  WEB: "Landing Page",
}

/**
 * Formats various data fields of an application object including phone numbers and dates.
 * This function mutates the input application object directly.
 *
 * @param {IDTMApplication} application - The application object to be formatted.
 * @returns {void} Returns nothing as it mutates the input object directly.
 */
export const makeDTMApplicationUIFriendly = (application: IDTMApplication): void => {
  if (!application) return;

  // Format phone numbers
  const phoneFields = ['bank_billing_contact_phone', 'bank_phone_number', 'business_phone', 'contact_phone'];
  phoneFields.forEach(field => {
    application[field] = DTM_PARSER.UI.phone(application[field]);
  });

  // Format dates
  application.business_start_date = DTM_PARSER.UI.date(application.business_start_date);

  // Format amounts
  const amountFields = ['sales_avg_ticket', 'sales_estimated_monthly_volume', 'sales_last_year_annual_sales', 'business_percent_customer_sales', 'business_percent_business_sales', 'business_percent_government_sales'];
  amountFields.forEach(field => {
    if (field === 'business_percent_business_sales') {
    }
    application[field] = DTM_PARSER.UI.int(application[field]);
  });

  // If API returns * then info is not accessible
  const hiddenFields = ['business_ein', 'bank_account_number'];
  hiddenFields.forEach(field => {
    application[field] = DTM_PARSER.UI.hiddenInfo(application[field]);
  });

  application.bank_fees_withdrawn_from_account = DTM_PARSER.UI.handleYesAndNo(application.bank_fees_withdrawn_from_account);

  Object.keys(application).forEach(key => {
    if (!isNullOrUndefinedOrEmpty(application[key]) && application[key] === "null") {
      application[key] = null;
    }
  });
}

/**
 * Formats various data fields of an application object including phone numbers and dates.
 * This function mutates the input application object directly.
 *
 * @param {IDTMApplication} application - The application object to be formatted.
 * @returns {FormData} Returns a form data version of the application.
 */
export const makeDTMApplicationAPIFriendly = (application: IDTMApplication): FormData => {
  if (!application) return;

  // Format phone numbers
  const phoneFields = ['bank_billing_contact_phone', 'bank_phone_number', 'business_phone', 'contact_phone'];
  phoneFields.forEach(field => {
    application[field] = DTM_PARSER.API.phone(application[field]);
  });

  // Format dates
  application.business_start_date = DTM_PARSER.API.date(application.business_start_date);

  // Format amounts
  const amountFields = ['sales_avg_ticket', 'sales_estimated_monthly_volume', 'sales_last_year_annual_sales', 'business_percent_customer_sales', 'business_percent_business_sales', 'business_percent_government_sales'];
  amountFields.forEach(field => {
    application[field] = DTM_PARSER.API.amount(application[field]);
  });

  // handle docs
  const docFields = ['doc_voided_check', 'doc_refund_policy', 'doc_incorporation_articles', 'doc_ein_letter', 'doc_monthly_statement_1', 'doc_monthly_statement_2', 'doc_monthly_statement_3', 'doc_year_profit_and_loss', 'doc_year_balance_sheet'];
  docFields.forEach(field => {
    application[field] = DTM_PARSER.API.handleStringFiles(application[field]);
  });

  // Remove null properties from the payload.
  const ignore = ['legal_address1', 'legal_address2', 'legal_city', 'legal_state', 'legal_zip_code'];
  Object.keys(application).forEach(key => {
    if (isNullOrUndefinedOrEmpty(application[key]) && !ignore.includes(key)) {
      delete application[key];
    }
  });

  // We don't want to pass these properties back to the API
  delete application.id;
  delete application["owners"];
  delete application.underwriting_status;
  delete application["how_many_25_more"];
  delete application["created_at"];
  delete application["updated_at"];
  delete application["merchant"];
  delete application["created_by"];
  delete application["updated_by"];

  // Create a form data object
  const body = new FormData();
  Object.keys(application).forEach(key => {
    body.append(key, application[key]);
  });
  return body;
}

/**
 * Formats various data fields of application owner object including phone numbers and dates.
 * This function mutates the input application owner object directly.
 *
 * @param {IDTMOwner} owner - The application owner object to be formatted.
 * @returns {void} Returns nothing as it mutates the input object directly.
 */
export const makeDTMOwnerUIFriendly = (owner: IDTMOwner): void => {
  if (isNullOrUndefined(owner)) return;

  const dateFields = ['dob', 'drivers_license_expiration'];
  dateFields.forEach(field => {
    owner[field] = DTM_PARSER.UI.date(owner[field]);
  });
  owner.phone = DTM_PARSER.UI.phone(owner.phone);
  owner.ownership_percent = DTM_PARSER.UI.int(owner.ownership_percent);

  // If API returns * then info is not accessible
  const hiddenFields = ['ssn', 'drivers_license'];
  hiddenFields.forEach(field => {
    owner[field] = DTM_PARSER.UI.hiddenInfo(owner[field]);
  });

  Object.keys(owner).forEach(key => {
    if (!isNullOrUndefinedOrEmpty(owner[key]) && owner[key] === "null") {
      owner[key] = null;
    }
  });
}

/**
 * Formats various data fields of application owner object including phone numbers and dates.
 * This function mutates the input application owner object directly.
 *
 * @param {IDTMOwner} owner - The application owner object to be formatted.
 * @returns {FormData} Returns a form data version of the owner.
 */
export const makeDTMOwnerAPIFriendly = (owner: IDTMOwner): FormData => {
  if (isNullOrUndefined(owner)) return;

  if (owner?.dob) {
    owner.drivers_license_dob = owner.dob;
  }

  const docFields = ['doc_drivers_license'];
  docFields.forEach(field => {
    owner[field] = DTM_PARSER.API.handleStringFiles(owner[field]);
  });

  const dateFields = ['dob', 'drivers_license_expiration', 'drivers_license_dob'];
  dateFields.forEach(field => {
    owner[field] = DTM_PARSER.API.date(owner[field]);
  });
  owner.phone = DTM_PARSER.API.phone(owner.phone);
  owner.ownership_percent = DTM_PARSER.API.amount(owner.ownership_percent).toString();
  if (owner?.ssn) {
    owner.ssn = stripOutNonDigits(owner?.ssn);
  }

  // We don't want to pass these properties back to the API
  delete owner.id;
  delete owner["dtm_application"];
  delete owner["created_at"];
  delete owner["updated_at"];
  delete owner["created_by"];
  delete owner["updated_by"];
  if (isNullOrUndefined(owner.doc_drivers_license)) {
    delete owner.doc_drivers_license;
  }
  if (isNullOrUndefinedOrEmpty(owner.ssn) || isOnlyAsterisks(owner.ssn)) {
    delete owner.ssn;
  }
  if (isNullOrUndefined(owner.drivers_license) || isOnlyAsterisks(owner.drivers_license)) {
    delete owner.drivers_license;
  }

  // Create a form data object
  const body = new FormData();
  Object.keys(owner).forEach(key => {
    body.append(key, owner[key]);
  });

  return body;
}

export const formatDashboardRate = (rate: number): string => {
  if (rate < 10) {
    return rate.toFixed(1); // If number is smaller than 10, displays one decimal place.
  }
  return rate.toFixed(0); // Otherwise, do not display any decimal numbers.
}

/**
 * Truncates a string to a specified maximum length by inserting ellipses (...) in the middle,
 * preserving the beginning and end of the string.
 *
 * @param {string} text - The original string to be truncated.
 * @param {number} max - The maximum length of the string after truncation.
 * @returns {string} - The truncated string with ellipses (...) added in the middle if needed.
 */
export const truncateString = (text: string, max: number): string => {
  if (isNullOrUndefinedOrEmpty(text) || text.length <= max) {
    return text;
  }
  const startChars = Math.ceil((max - 3) / 2);
  const endChars = Math.floor((max - 3) / 2);
  return text.substring(0, startChars) + '...' + text.substring(text.length - endChars);
};

export function stringAmountToNumber(amount: string) {
  // Remove all characters except digits and the period
  const cleanedString = amount?.replace(/[^0-9.]/g, '');

  // Convert the cleaned string to a number
  const result = parseFloat(cleanedString);

  // If the result is not a number (NaN), return 0 or handle it as needed
  return isNaN(result) ? 0 : result;
}

export const formatLenderTypeAndSource = (lender_type: string, source: string): string => {
  if (isNullOrUndefinedOrEmpty(lender_type) && isNullOrUndefinedOrEmpty(source)) {
    return "";
  }

  return `${lender_type}, ${LOAN_APP_SOURCE_MAPPING[source] || source}`;
}