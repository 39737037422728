import Environment from "classes/Environment";
import LogRocket from 'logrocket';

export let segmentKey: string = "sa8Xk0kqFG73go162SCwHJCpS2CCDtGx";
export let gaKey: string = "G-R9DL4ZZWLR";
export let logRocketKey: string = "qiuraz/cloudsy-dev";

/** 
 * @description Load Segment script
 */
export function loadSegmentScript() {
  if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
    if (Environment.isProduction) {
      segmentKey = "NHq9YmSkGvBo7PCGBZCqauLDHDNqxLKI";
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.segment.com/analytics.js/v1/${segmentKey}/analytics.min.js`;
    document.body.appendChild(script);

    script.onload = () => {
      console.log("Segment is loaded");
    };
  }
}

/** 
 * @description Load GA script
 */
export function loadGAScript() {
  if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
    if (Environment.isProduction) {
      gaKey = "G-DMP2CGX83V";
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaKey}`;
    document.body.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () { window.dataLayer.push(arguments); };
      window.gtag('js', new Date());
      window.gtag('config', gaKey);

      console.log("GA is loaded");
    };
  }
}

export function loadNewRelicScript() {
  if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `/assets/scripts/new-relic-${Environment.isProduction ? "prod" : "dev"}.js`;
    document.body.appendChild(script);

    script.onload = () => {
      console.log("New Relic is loaded");
    };
  }
}

export function loadLogRocket() {
  if (process.env.REACT_APP_TRACK_ANALYTICS === "true") {
    if (Environment.isProduction) {
      logRocketKey = "qiuraz/cloudsy";
    }
    LogRocket.init(logRocketKey);  
  }
}